import { CSSProperties, ReactNode } from 'react';
// form
import { FormProvider as Form, UseFormReturn } from 'react-hook-form';

// ----------------------------------------------------------------------

type Props = {
  children: ReactNode;
  methods: UseFormReturn<any>;
  onSubmit?: VoidFunction;
  className?: string;
  style?: CSSProperties | undefined;
};

export default function FormProvider({ children, onSubmit, methods, className, style }: Props) {
  return (
    <Form {...methods}>
      <form onSubmit={onSubmit} className={className} style={style}>
        {children}
      </form>
    </Form>
  );
}
