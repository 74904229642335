// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import SvgIconStyle from '../../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name: string) => (
  <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  booking: getIcon('ic_booking'),
  invoice: getIcon('ic_invoice'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  settings: getIcon('ic_gear'),
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'general',
    items: [
      { title: 'Dashboard', path: PATH_DASHBOARD.general.app, icon: ICONS.dashboard },
      { title: 'calendar', path: PATH_DASHBOARD.calendar.root, icon: ICONS.calendar },
      { title: 'settings', path: PATH_DASHBOARD.general.settings, icon: ICONS.settings },
    ],
  },

  // MANAGEMENT
  // ----------------------------------------------------------------------
  {
    subheader: 'management',
    items: [
      // USER
      {
        title: 'users',
        path: PATH_DASHBOARD.user.list,
        icon: ICONS.user,
      },

      // E-COMMERCE
      {
        title: 'inventory',
        path: PATH_DASHBOARD.inventory.list,
        icon: ICONS.cart,
      },

      // TRANSACTIONS
      {
        title: 'transactions',
        path: PATH_DASHBOARD.transaction.list,
        icon: ICONS.invoice,
      },

      // BLOG
      {
        title: 'reservations',
        path: PATH_DASHBOARD.reservation.root,
        icon: ICONS.blog,
      },
    ],
  },

  // APP
  // ----------------------------------------------------------------------
];

export default navConfig;
