import React, { createContext, FC, ReactNode, useEffect, useReducer } from 'react';
import { BinItem } from 'src/@types/item';
import {
  Shopper,
  ShoppingActions,
  ShoppingContextType,
  ShoppingState,
  Types,
} from 'src/@types/shopping';
import useLocalStorage from 'src/hooks/useLocalStorage';

const initialState: ShoppingState = {
  cart: [],
  timer: null,
  shopper: null,
  showScanner: false,
};

const ShoppingContext = createContext<ShoppingContextType>({
  ...initialState,
  setShopper: (shopper: Shopper | null) => {},
  updateCart: (items: BinItem[]) => {},
  deleteItem: (id: string) => {},
  toggleScanner: (showScanner: boolean) => {},
  startTimer: () => {},
  endTimer: () => {},
  clearShoppingData: () => {},
});

const reducer = (state: ShoppingState, action: ShoppingActions) => {
  switch (action.type) {
    case Types.SET_SHOPPER:
      const { shopper } = action.payload;
      return {
        ...state,
        shopper,
      };
    case Types.SET_TIME:
      const { timer } = action.payload;
      return {
        ...state,
        timer,
      };
    case Types.SET_CART:
      const { cart } = action.payload;
      return {
        ...state,
        cart,
      };
    case Types.TOGGLE_SCANNER:
      const { showScanner } = action.payload;
      return {
        ...state,
        showScanner,
      };
    default:
      return state;
  }
};

type ShoppingProviderProps = {
  children: ReactNode;
};

const ShoppingProvider: FC<ShoppingProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const [cart, setCart] = useLocalStorage<BinItem[]>('cart', []);
  const [shopperDetails, setShopperDetails] = useLocalStorage<Shopper | null>('shopper', null);
  const [timerInfo, setTimerInfo] = useLocalStorage<string | null>('timer', null);

  const setShopper = (shopper: Shopper | null) => {
    dispatch({
      type: Types.SET_SHOPPER,
      payload: {
        shopper,
      },
    });
    setShopperDetails(shopper);
  };

  const updateCart = (items: BinItem[]) => {
    const updatedCart = JSON.parse(JSON.stringify(state.cart));
    items.forEach((item) => {
      const idx = updatedCart.findIndex((cartItem: BinItem) => cartItem._id === item._id);
      if (idx >= 0) {
        if (item.takeAmount > 0) {
          updatedCart.splice(idx, 1, item);
        } else {
          updatedCart.splice(idx, 1);
        }
      } else {
        updatedCart.unshift(item);
      }
    });

    dispatch({
      type: Types.SET_CART,
      payload: {
        cart: updatedCart,
      },
    });

    setCart(updatedCart);
  };

  const clearShoppingData = () => {
    dispatch({
      type: Types.SET_CART,
      payload: {
        cart: [],
      },
    });
    setCart([]);
    setShopper(null);
    endTimer();
  };

  const deleteItem = (id: string) => {
    const cart = state.cart;
    const idx = cart.findIndex((item) => item._id === id);
    cart.splice(idx, 1);

    dispatch({
      type: Types.SET_CART,
      payload: {
        cart,
      },
    });
    setCart(cart);
  };

  const toggleScanner = (showScanner: boolean) => {
    dispatch({
      type: Types.TOGGLE_SCANNER,
      payload: {
        showScanner,
      },
    });
  };

  const startTimer = () => {
    let timer = null;
    if (timerInfo) {
      timer = timerInfo;
    } else {
      var date = new Date();
      date.setMinutes(date.getMinutes() + 30);
      setTimerInfo(date);
      timer = date;
    }

    dispatch({
      type: Types.SET_TIME,
      payload: {
        timer,
      },
    });
  };

  const endTimer = () => {
    setTimerInfo(null);
    dispatch({
      type: Types.SET_TIME,
      payload: {
        timer: null,
      },
    });
  };

  useEffect(() => {
    dispatch({
      type: Types.SET_CART,
      payload: {
        cart,
      },
    });
    setShopper(shopperDetails);
  }, []);

  const value = {
    cart: state.cart,
    shopper: state.shopper,
    timer: state.timer,
    showScanner: state.showScanner,
    setShopper,
    updateCart,
    toggleScanner,
    startTimer,
    endTimer,
    deleteItem,
    clearShoppingData,
  };

  return <ShoppingContext.Provider value={value}>{children}</ShoppingContext.Provider>;
};

export { ShoppingContext, ShoppingProvider };
