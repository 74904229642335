import { ActionMap } from './auth';
import { BinItem } from './item';

export type Shopper = {
  _id: string;
  userId: string;
  firstName: string;
  lastName: string;
  grade: string;
  classLoad: number;
  school: string;
  email: string;
};

export interface ShoppingState {
  cart: BinItem[];
  timer: string | null;
  shopper: Shopper | null;
  showScanner: boolean;
}

export interface TimerState {
  minutes: number;
  seconds: number;
}

export interface ShoppingContextType extends ShoppingState {
  setShopper: (shopper: Shopper | null) => void;
  updateCart: (items: BinItem[]) => void;
  deleteItem: (id: string) => void;
  toggleScanner: (showScanner: boolean) => void;
  startTimer: () => void;
  endTimer: () => void;
  clearShoppingData: () => void;
}

export enum Types {
  SET_SHOPPER = 'SET_SHOPPER',
  SET_TIME = 'SET_TIME',
  SET_CART = 'SET_CART',
  TOGGLE_SCANNER = 'TOGGLE_SCANNER',
}

export type ShoppingPayload = {
  [Types.SET_SHOPPER]: {
    shopper: Shopper | null;
  };
  [Types.SET_TIME]: {
    timer: string | null;
  };
  [Types.SET_CART]: {
    cart: BinItem[];
  };
  [Types.TOGGLE_SCANNER]: {
    showScanner: boolean;
  };
};

export type ShoppingActions = ActionMap<ShoppingPayload>[keyof ActionMap<ShoppingPayload>];
