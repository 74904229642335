export default {
  arrow: {
    empty: {
      right: 'eva:chevron-right-fill',
      left: 'eva:chevron-left-fill',
      down: 'eva:chevron-down-fill',
      up: 'eva:chevron-up-fill',
    },
    filled: {
      right: 'eva:arrow-right-fill',
      left: 'eva:arrow-left-fill',
      up: 'eva:arrow-up-fill',
      down: 'eva:arrow-down-fill',
    },
    full: {
      diagonalDownLeft: 'eva:diagonal-arrow-left-down-fill',
      diagonalUpRight: 'eva:diagonal-arrow-right-up-fill',
      left: 'akar-icons:arrow-left',
      right: 'akar-icons:arrow-right',
      back: 'akar-icons:arrow-back'
    },
    circled: {
      down: 'eva:arrow-circle-down-fill'
    }
  },
  attachment: 'eva:attach-2-fill',
  bell: 'eva:bell-fill',
  calendar: 'ant-design:calendar-filled',
  circleAlert: 'eva:alert-circle-fill',
  checkCircle: 'eva:checkmark-circle-2-fill',
  checkMark: 'eva:checkmark-fill',
  clearAll: 'ic:round-clear-all',
  clock: {
    outline: 'eva:clock-outline',
    filled: 'eva:clock-fill',
  },
  close: 'eva:close-fill',
  collapse: 'eva:collapse-fill',
  company: {
    apple: 'ant-design:apple-filled',
    windows: 'ant-design:windows-filled'
  },
  copyIcon: 'eva:copy-fill',
  download: 'akar-icons:download',
  doubleCheck: 'eva:done-all-fill',
  duotone: {
    left: 'ph:align-left-duotone',
    right: 'ph:align-right-duotone',
    sun: 'ph:sun-duotone',
    moon: 'ph:moon-duotone',
  },
  editPencil: 'eva:edit-fill',
  email: 'clarity:email-line',
  eye: 'eva:eye-fill',
  eyeOff: 'eva:eye-off-fill',
  faq: 'wpf:faq',
  file: 'eva:file-fill',
  filterOptions: 'eva:options-2-fill',
  filterList: 'ic:round-filter-list',
  fullscreen: {
    exit: 'ic:round-fullscreen-exit',
    open: 'ic:round-fullscreen'
  },
  heart: 'eva:heart-fill',
  home: 'ant-design:home-filled',
  info: 'eva:info-fill',
  infoCard: 'fluent:contact-card-32-filled',
  importantLabel: 'ic:round-label-important',
  key: 'ic:round-vpn-key',
  lighting: 'eva:flash-fill',
  link: 'eva:link-fill',
  list: 'eva:list-fill',
  listSolid: 'clarity:list-solid',
  login: 'clarity:login-solid',
  mapPin: 'eva:pin-fill',
  media: 'ic:round-perm-media',
  menu: 'eva:menu-2-fill',
  message: 'eva:message-square-fill',
  mic: 'eva:mic-fill',
  minus: 'akar-icons:minus',
  more: 'eva:more-vertical-fill',
  moreHorizontal: 'eva:more-horizontal-fill',
  newCamera: 'ic:round-add-a-photo',
  newImage: 'ic:round-add-photo-alternate',
  pdf: 'ant-design:file-pdf-filled',
  people: 'eva:people-fill',
  personInSquare: 'ic:round-account-box',
  phone: 'eva:phone-fill',
  plus: 'akar-icons:plus',
  power: 'ic:round-power-settings-new',
  printer: 'eva:printer-fill',
  qrCode: 'bx:qr-scan',
  radioChecked: 'eva:checkmark-circle-2-outline',
  radioOff: 'eva:radio-button-off-outline',
  receipt: 'ic:round-receipt',
  redo: 'ic:round-redo',
  refresh: 'ic:round-refresh',
  reply: 'ic:round-reply',
  save: 'lets-icons:save',
  search: 'eva:search-fill',
  send: 'ic:round-send',
  settings: 'eva:settings-2-fill',
  shareFill: 'eva:share-fill',
  shoppingCart: 'clarity:shopping-cart-solid',
  shoppingCartAdd: 'ic:round-add-shopping-cart',
  shield: 'eva:shield-fill',
  smilingFace: 'eva:smiling-face-fill',
  socialMedia: {
    facebook: {
      letter: 'eva:facebook-fill',
      logo: 'ic:baseline-facebook',
    },
    google: {
      letter: 'eva:google-fill',
    },
    linkedIn: {
      letter: 'eva:linkedin-fill',
    },
    twitter: {
      logo: 'eva:twitter-fill',
    },
    instagram: {
      logo: 'ant-design:instagram-filled',
    },
  },
  star: 'ant-design:star-filled',
  starOutline: 'ant-design:star-outlined',
  thumbsUp: 'ic:round-thumb-up',
  trash: 'bxs:trash-alt',
  trending: {
    up: 'eva:trending-up-fill',
    down: 'eva:trending-down-fill',
  },
  triangleAlert: 'eva:alert-triangle-fill',
  trophy: 'ant-design:trophy-filled',
  undo: 'ic:round-undo',
  verified: 'ic:round-verified',
  verticalDots: 'eva:more-vertical-fill',
  video: 'eva:video-fill'
};
