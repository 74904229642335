// routes
import { PATH_AUTH, PATH_DOCS, PATH_PAGE } from '../../routes/paths';
// components
import { PATH_AFTER_LOGIN } from '../../config';
// components
import Iconify from '../../components/Iconify';
import useResponsive from 'src/hooks/useResponsive';
import appIcons from "../../appIcons";

// ----------------------------------------------------------------------

export const ICON_SIZE = {
  width: 22,
  height: 22,
};

const useNavMenu = () => {
  const isMobile = useResponsive('only', 'xs');

  const navMenu = [
    {
      title: 'Home',
      icon: <Iconify icon={appIcons.home} {...ICON_SIZE} />,
      path: '/',
    },
    {
      title: 'Inventory',
      icon: <Iconify icon={appIcons.listSolid} {...ICON_SIZE} />,
      path: PATH_PAGE.inventory,
    },
    {
      title: 'Reservation',
      icon: <Iconify icon={appIcons.calendar} {...ICON_SIZE} />,
      path: PATH_PAGE.reservation,
    },
    {
      title: 'Contact',
      icon: <Iconify icon={appIcons.infoCard} {...ICON_SIZE} />,
      path: PATH_PAGE.contact,
    },

    {
      title: 'FAQ',
      icon: <Iconify icon={appIcons.faq} {...ICON_SIZE} />,
      path: PATH_PAGE.faqs,
    },
    {
      title: 'Login',
      icon: <Iconify icon={appIcons.login} {...ICON_SIZE} />,
      path: PATH_AUTH.login,
    },
    // {
    //   title: 'Pages',
    //   path: '/pages',
    //   icon: <Iconify icon={appIcons.file} {...ICON_SIZE} />,
    //   children: [
    //     {
    //       subheader: 'Other',
    //       items: [
    //         { title: 'About us', path: PATH_PAGE.about },
    //         { title: 'Contact us', path: PATH_PAGE.contact },
    //         { title: 'FAQs', path: PATH_PAGE.faqs },
    //         { title: 'Pricing', path: PATH_PAGE.pricing },
    //         { title: 'Payment', path: PATH_PAGE.payment },
    //         { title: 'Maintenance', path: PATH_PAGE.maintenance },
    //         { title: 'Coming Soon', path: PATH_PAGE.comingSoon },
    //       ],
    //     },
    //     {
    //       subheader: 'Authentication',
    //       items: [
    //         { title: 'Login', path: PATH_AUTH.loginUnprotected },
    //         { title: 'Register', path: PATH_AUTH.registerUnprotected },
    //         { title: 'Reset password', path: PATH_AUTH.resetPassword },
    //         { title: 'Verify code', path: PATH_AUTH.verify },
    //       ],
    //     },
    //     {
    //       subheader: 'Error',
    //       items: [
    //         { title: 'Page 404', path: PATH_PAGE.page404 },
    //         { title: 'Page 500', path: PATH_PAGE.page500 },
    //       ],
    //     },
    //     {
    //       subheader: 'Dashboard',
    //       items: [{ title: 'Dashboard', path: PATH_AFTER_LOGIN }],
    //     },
    //   ],
    // },
    // {
    //   title: 'Documentation',
    //   icon: <Iconify icon={'eva:book-open-fill'} {...ICON_SIZE} />,
    //   path: PATH_DOCS,
    // },
  ];

  isMobile &&
    navMenu.splice(2, 0, {
      title: 'Shop',
      icon: <Iconify icon={appIcons.shoppingCart} {...ICON_SIZE} />,
      path: PATH_PAGE.shop,
    });

  return navMenu;
};

export default useNavMenu;
