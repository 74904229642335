import { Suspense, lazy, ElementType } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// guards
import useAuth from '../hooks/useAuth';
// layouts
import MainLayout from '../layouts/main';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// config
import { PATH_AFTER_LOGIN } from '../config';
// components
import LoadingScreen from '../components/LoadingScreen';

// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { isAuthenticated } = useAuth();

  const isDashboard = pathname.includes('/dashboard') && isAuthenticated;

  return (
    <Suspense fallback={<LoadingScreen isDashboard={isDashboard} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          ),
        },
        { path: 'login-unprotected', element: <Login /> },
        { path: 'register-unprotected', element: <Register /> },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'verify', element: <VerifyCode /> },
      ],
    },

    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'app', element: <GeneralApp /> },
        { path: 'settings', element: <Settings /> },
        { path: 'reservation', element: <ReservationList /> },

        {
          path: 'user',
          children: [
            { element: <Navigate to="/dashboard/user/profile" replace />, index: true },
            { path: 'profile', element: <UserProfile /> },
            { path: 'cards', element: <UserCards /> },
            { path: 'list', element: <UserList /> },
            { path: 'new', element: <UserCreate /> },
            { path: ':userId/edit', element: <UserCreate /> },
            { path: 'account', element: <UserAccount /> },
          ],
        },
        {
          path: 'transaction',
          children: [
            { element: <Navigate to="/dashboard/transaction/list" replace />, index: true },
            { path: 'list', element: <TransactionList /> },
            { path: ':id', element: <ModifyTransaction /> },
            { path: 'new', element: <ModifyTransaction /> },
          ],
        },
        {
          path: 'inventory',
          children: [
            { element: <Navigate to="/dashboard/inventory/list" replace />, index: true },
            { path: 'list', element: <InventoryList /> },
            { path: ':id', element: <ModifyInventory /> },
            { path: 'new', element: <ModifyInventory /> },
          ],
        },
        {
          path: 'calendar',
          children: [
            { element: <Calendar />, index: true },
            { path: 'event/new', element: <ModifyEvent /> },
            { path: 'event/:id', element: <ModifyEvent /> },
          ],
        },
      ],
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'coming-soon', element: <ComingSoon /> },
        { path: 'maintenance', element: <Maintenance /> },
        { path: 'pricing', element: <Pricing /> },
        { path: 'payment', element: <Payment /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    {
      path: '/',
      element: <MainLayout />,
      children: [
        { element: <HomePage />, index: true },
        { path: 'about-us', element: <About /> },
        { path: 'inventory', element: <Inventory /> },
        { path: 'reservation', element: <Reservation /> },
        { path: 'shop', element: <Shopping /> },
        { path: 'reserve/:eventId', element: <ReserveSpot /> },
        { path: 'user-reservations/:id', element: <ReservationInfo /> },
        { path: 'checkout-success', element: <CheckoutSuccess /> },
        { path: 'contact-us', element: <Contact /> },
        { path: 'faqs', element: <Faqs /> },
        { path: 'privacy-policy', element: <PrivacyPolicy /> },
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// AUTHENTICATION
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const Register = Loadable(lazy(() => import('../pages/auth/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));
const VerifyCode = Loadable(lazy(() => import('../pages/auth/VerifyCode')));

const PrivacyPolicy = Loadable(lazy(() => import('../pages/PrivacyPolicy')))

// GENERAL
const GeneralApp = Loadable(lazy(() => import('../pages/admin/GeneralApp')));
const Settings = Loadable(lazy(() => import('../pages/admin/Settings')));
const ReservationList = Loadable(lazy(() => import('../pages/admin/ReservationList')));

// TRANSACTIONS
const TransactionList = Loadable(lazy(() => import('../pages/admin/TransactionList')));
const ModifyTransaction = Loadable(lazy(() => import('../pages/admin/ModifyTransaction')));

// INVENTORY
const InventoryList = Loadable(lazy(() => import('../pages/admin/InventoryList')));
const ModifyInventory = Loadable(lazy(() => import('../pages/admin/ModifyInventory')));

// USER
const UserProfile = Loadable(lazy(() => import('../pages/dashboard/UserProfile')));
const UserCards = Loadable(lazy(() => import('../pages/dashboard/UserCards')));
const UserList = Loadable(lazy(() => import('../pages/dashboard/UserList')));
const UserAccount = Loadable(lazy(() => import('../pages/dashboard/UserAccount')));
const UserCreate = Loadable(lazy(() => import('../pages/admin/UserCreate')));

// APP
const Calendar = Loadable(lazy(() => import('../pages/admin/Calendar')));
const ModifyEvent = Loadable(lazy(() => import('../pages/admin/ModifyEvent')));

// MAIN
const HomePage = Loadable(lazy(() => import('../pages/Home')));
const About = Loadable(lazy(() => import('../pages/About')));
const Contact = Loadable(lazy(() => import('../pages/Contact')));
const Reservation = Loadable(lazy(() => import('../pages/Reservation')));
const Inventory = Loadable(lazy(() => import('../pages/Inventory')));
const Shopping = Loadable(lazy(() => import('../pages/Shopping')));
const ReserveSpot = Loadable(lazy(() => import('../pages/ReserveSpot')));
const ReservationInfo = Loadable(lazy(() => import('../pages/ReservationInfo')));
const CheckoutSuccess = Loadable(lazy(() => import('../pages/CheckoutSuccess')));
const Faqs = Loadable(lazy(() => import('../pages/Faqs')));
const ComingSoon = Loadable(lazy(() => import('../pages/ComingSoon')));
const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));
const Pricing = Loadable(lazy(() => import('../pages/Pricing')));
const Payment = Loadable(lazy(() => import('../pages/Payment')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
