import { useLocation, Outlet } from 'react-router-dom';
// @mui
import { Stack } from '@mui/material';
// components
//
import MainFooter from './MainFooter';
import MainHeader from './MainHeader';

// ----------------------------------------------------------------------

export default function MainLayout() {
  const { pathname } = useLocation();

  const isHome = pathname === '/';
  const isShopping = pathname === '/shop';

  return (
    <Stack sx={{ minHeight: 1 }}>
      <MainHeader />

      <Outlet />

      {!isHome && !isShopping && <MainFooter />}
    </Stack>
  );
}
